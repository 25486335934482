<template>
    <div id="page-not-found">
        <div class="container d-flex flex-row justify-content-center align-items-center h-100">
            <h1>Ops! Não encontramos esta página!<br/></h1>
        </div>
    </div>
</template>

<style type="text/css">
html, body, main, #page-not-found
{
    height: 100%
}
</style>

<script>
export default {
    name: 'page-not-found',
    created() {
        document.title = 'Página não encontrada'
    }
}
</script>
